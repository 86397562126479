import { IdentificationType } from 'policyholder/domain/policyholder-identification';
import { isValidSAIdNumber } from 'rootstrap/components/forms/new-fields/extended-components/id-number-field';
import { JSONObject } from 'shared/utils';
import { ContactDetailsData } from '../views/contact-details';
import { PersonalDetailsIdentificationData } from '../views/identification';
import { PersonalDetailsInputs } from '../views/personal-details';
import { PersonalAddressData } from '../views/physical-address-section';
import { PolicyholderEntityType } from 'policyholder/domain/policyholder';

interface Params {
  identificationSectionData: PersonalDetailsIdentificationData | undefined;
  addressDetailsSectionData: PersonalAddressData | undefined;
  contactDetailsSectionData: ContactDetailsData | undefined;
  addressOptIn: boolean | undefined;
}

export const isPolicyholderLookupPrefillComplete = (params: {
  identificationSectionData: PersonalDetailsIdentificationData | undefined;
}) => {
  const { identificationSectionData } = params;
  const optionalFields = getOptionalPolicyholderIdFields({
    idType: identificationSectionData?.idType,
    policyholderType: identificationSectionData?.policyholderType,
  });

  const isIdentificationSectionComplete =
    identificationSectionData &&
    isPolicyholderSectionComplete<PersonalDetailsIdentificationData>(identificationSectionData, [
      ...optionalFields,
      PersonalDetailsInputs.FirstName,
      PersonalDetailsInputs.LastName,
      PersonalDetailsInputs.DateOfBirth,
      PersonalDetailsInputs.Gender,
    ]);

  const isIdentificationValid =
    identificationSectionData?.idType === IdentificationType.Id && identificationSectionData.identificationNumber
      ? isValidSAIdNumber(identificationSectionData.identificationNumber)
      : true;

  return isIdentificationSectionComplete && isIdentificationValid;
};

export const isPolicyholderPrefillComplete = (params: Params) => {
  const { addressDetailsSectionData, contactDetailsSectionData, identificationSectionData } = params;
  const optionalFields = getOptionalPolicyholderIdFields({
    idType: identificationSectionData?.idType,
    policyholderType: identificationSectionData?.policyholderType,
  });

  const isAddressDetailsSectionComplete =
    addressDetailsSectionData &&
    isPolicyholderSectionComplete<PersonalAddressData>(addressDetailsSectionData, optionalFields);

  const isIdentificationSectionComplete =
    identificationSectionData &&
    isPolicyholderSectionComplete<PersonalDetailsIdentificationData>(identificationSectionData, optionalFields);

  const isContactDetailsSectionComplete =
    contactDetailsSectionData &&
    isPolicyholderSectionComplete<ContactDetailsData>(contactDetailsSectionData, optionalFields);

  const isIdentificationValid =
    identificationSectionData?.idType === IdentificationType.Id && identificationSectionData.identificationNumber
      ? isValidSAIdNumber(identificationSectionData.identificationNumber)
      : true;

  return (
    isIdentificationSectionComplete &&
    isContactDetailsSectionComplete &&
    isAddressDetailsSectionComplete &&
    isIdentificationValid
  );
};

export const isPolicyholderSectionComplete = <T,>(sectionData: T, optionalFields: string[]) => {
  // Check for specific conditions related to AreaCode and Country
  if (
    (sectionData as any)[PersonalDetailsInputs.AreaCode] &&
    (sectionData as any)[PersonalDetailsInputs.Country] === 'ZA'
  ) {
    const areaCode = (sectionData as any)[PersonalDetailsInputs.AreaCode];
    if (areaCode.length !== 4 || /[a-zA-Z]/.test(areaCode)) {
      console.log(`Invalid AreaCode: ${areaCode}`);
      return false;
    }
  }

  // Identify keys that are required but missing
  const incompleteFields = Object.keys(sectionData as unknown as JSONObject).filter((key) => {
    // Field is not optional and is undefined or null
    return (
      !optionalFields.includes(key) &&
      (sectionData[key as keyof T] === undefined ||
        sectionData[key as keyof T] === '' ||
        sectionData[key as keyof T] === null)
    );
  });

  // If there are incomplete fields, log them
  if (incompleteFields.length > 0) {
    return false;
  }

  // All required fields are present
  return true;
};

export const getOptionalPolicyholderIdFields = (params: {
  idType: IdentificationType | undefined;
  policyholderType: PolicyholderEntityType | undefined;
}) => {
  const alwaysOptional = [PersonalDetailsInputs.AddressLine2];
  const { idType, policyholderType } = params;

  if (policyholderType === PolicyholderEntityType.Company) {
    const optionalFields: string[] = [
      PersonalDetailsInputs.IdentificationExpirationDate,
      PersonalDetailsInputs.IdentificationExpirationDate,
      PersonalDetailsInputs.Gender,
      PersonalDetailsInputs.DateOfBirth,
      PersonalDetailsInputs.IdentificationNumber,
      PersonalDetailsInputs.IdType,
      PersonalDetailsInputs.IdentificationCountry,
    ];

    return [...alwaysOptional, ...optionalFields];
  }

  if (idType === IdentificationType.Id) {
    const optionalFields = [
      PersonalDetailsInputs.IdentificationCountry,
      PersonalDetailsInputs.DateOfBirth,
      PersonalDetailsInputs.IdentificationExpirationDate,
      PersonalDetailsInputs.Gender,
      PersonalDetailsInputs.CompanyName,
      PersonalDetailsInputs.CompanyWebsiteUrl,
      PersonalDetailsInputs.DateOfEstablishment,
      PersonalDetailsInputs.SubsidiaryCompanies,
      PersonalDetailsInputs.RegistrationNumber,
      PersonalDetailsInputs.ContactPosition,
    ];

    return [...alwaysOptional, ...optionalFields];
  }

  if (idType === IdentificationType.Cellphone) {
    const optionalFields = [
      PersonalDetailsInputs.IdentificationCountry,
      PersonalDetailsInputs.DateOfBirth,
      PersonalDetailsInputs.IdentificationExpirationDate,
      PersonalDetailsInputs.Gender,
      PersonalDetailsInputs.CompanyName,
      PersonalDetailsInputs.CompanyWebsiteUrl,
      PersonalDetailsInputs.DateOfEstablishment,
      PersonalDetailsInputs.SubsidiaryCompanies,
      PersonalDetailsInputs.RegistrationNumber,
      PersonalDetailsInputs.ContactPosition,
    ];

    return [...alwaysOptional, ...optionalFields];
  }

  if (idType === IdentificationType.Email) {
    const optionalFields = [
      PersonalDetailsInputs.IdentificationCountry,
      PersonalDetailsInputs.DateOfBirth,
      PersonalDetailsInputs.IdentificationExpirationDate,
      PersonalDetailsInputs.Gender,
      PersonalDetailsInputs.CompanyName,
      PersonalDetailsInputs.CompanyWebsiteUrl,
      PersonalDetailsInputs.DateOfEstablishment,
      PersonalDetailsInputs.SubsidiaryCompanies,
      PersonalDetailsInputs.RegistrationNumber,
      PersonalDetailsInputs.ContactPosition,
    ];

    return [...alwaysOptional, ...optionalFields];
  }

  if (idType === IdentificationType.Passport) {
    const optionalFields: string[] = [
      PersonalDetailsInputs.IdentificationExpirationDate,
      PersonalDetailsInputs.CompanyName,
      PersonalDetailsInputs.CompanyWebsiteUrl,
      PersonalDetailsInputs.DateOfEstablishment,
      PersonalDetailsInputs.SubsidiaryCompanies,
      PersonalDetailsInputs.RegistrationNumber,
      PersonalDetailsInputs.ContactPosition,
    ];

    return [...alwaysOptional, ...optionalFields];
  }

  return [...alwaysOptional];
};
