import { IdentificationType } from 'policyholder/domain/policyholder-identification';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormWrapperStyle } from 'rootstrap/components-old/root-schema-form/root-schema-form';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { CardGroup } from 'rootstrap/components/card';
import { IdTypeSelectField } from 'rootstrap/components/forms/new-fields/id-type-select-field';
import {
  InputField,
  InputFieldDisplayProperties,
  PrefillAction,
} from 'rootstrap/components/forms/new-fields/input-field';
import { ActiveElement } from 'rootstrap/components/forms/new-fields/utils';
import { Col, Row } from 'rootstrap/components/grid/grid';
import { usePromise, usePromiseLazy } from 'shared/hooks/promise';
import { getWording, getWordingForIdTypeHandlebars } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import { PersonalDetailsInputs, PolicyholderPrefillValues, setPolicyholderState } from './personal-details';
import { getIdType, getIdTypeValue, getIdTypes } from '../utils/helpers';
import { ProductModule } from 'product-modules/domain/product-module';
import { SteppedComponentsBehavior } from 'rootstrap/components-old/root-schema-form/utils/stepped-components-behavior';
import { FormDetailsWrapper } from 'rootstrap/components/forms/form-details-wraper';
import { LoadingLines } from 'rootstrap/components-old/loaders/loading-lines';
import { TextField } from 'rootstrap/components/forms/new-fields/extended-components/text-field';
import { PhoneNumberField } from 'rootstrap/components/forms/new-fields/phone-number-field';
import phone from 'phone';
import { BCP47ToIsoCountryCode } from 'rootstrap/components/forms/countries';
import { ContactDetailCellphone } from './contact-details';
import { Cellphone, Policyholder, PolicyholderEntityType } from 'policyholder/domain/policyholder';
import { PolicyholderLookUpModal } from './policyholder-lookup-modal';
import { ErrorAlert } from 'rootstrap/components/error-alert';
import { CountrySelectField } from 'rootstrap/components/forms/new-fields/extended-components/country-select-field';
import { lookupUnauthenticatedPolicyholder } from 'policyholder/actions/lookup-unauthenticated-policyholder';
import { useEmbedParamsContext } from 'shared/embed-params-context';
import { isPolicyholderLookupPrefillComplete } from '../utils/is-policyholder-prefilled';
import { IssuingSceneStepKeys } from 'policy-issuing/utils';
export interface PolicyholderLookUpFormData {
  idType: IdentificationType | undefined;
  identificationNumber: string | undefined;
  passportNumber?: string;
  email?: string;
  cellphone?: ContactDetailCellphone;
  identificationCountry?: string;
  registrationNumber: string | undefined;
}

interface Props {
  policyholderLookupFormData: PolicyholderLookUpFormData;
  setPolicyholderLookupFormData: (v: PolicyholderLookUpFormData) => void;
  productModule: ProductModule | undefined;
  isCompleted: boolean;
  foundPolicyholder: Policyholder | undefined;
  isLookupPolicyholderModalOpen: boolean;
  lookupPolicyholderError?: Error;
  activeElement: ActiveElement;
  policyholderPrefillValues: PolicyholderPrefillValues;
  createPolicyholderSubmitButtonRef: React.MutableRefObject<any>;
  policyholder: Policyholder | undefined;
  isLoading: boolean;
  setLookupPolicyholderModalOpen: (v: boolean) => void;
  setPolicyholder: (p: Policyholder) => void;
  setCurrentStepKey: (s: IssuingSceneStepKeys) => void;
  setNewPersonalDetailsAccepted: (v: boolean) => void;
  setActiveElement: (params: ActiveElement) => void;
  setPolicyholderIsValid: (v: boolean) => void;
  resetPolicyholderData: () => void;
  setFoundPolicyholder: (policyholder: Policyholder) => void;
  isPolicyholderTypeFieldDisplayed: boolean;
  policyholderType: PolicyholderEntityType | undefined;
  setPolicyholderActiveElement: (params: ActiveElement) => void;
  setIsLoadingPolicyholderLookup: (isLoadingSubmitForm: boolean) => void;
}
export interface PersonalDetailsInputsFormData {
  [PersonalDetailsInputs.IdType]: IdentificationType | undefined;
  [PersonalDetailsInputs.IdentificationNumber]: string | undefined;
  [PersonalDetailsInputs.IdentificationCountry]: string | undefined;
  [PersonalDetailsInputs.Email]: string | undefined;
  [PersonalDetailsInputs.Cellphone]: ContactDetailCellphone | undefined;
  [PersonalDetailsInputs.RegistrationNumber]: string | undefined;
}

export const UnauthenticatedPolicyholderLookup = (props: Props) => {
  const {
    policyholderLookupFormData,
    productModule,
    foundPolicyholder,
    isLookupPolicyholderModalOpen,
    lookupPolicyholderError,
    policyholderPrefillValues,
    createPolicyholderSubmitButtonRef,
    policyholder,
    isLoading,
    setLookupPolicyholderModalOpen,
    setPolicyholderLookupFormData,
    setCurrentStepKey,
    setPolicyholder,
    setNewPersonalDetailsAccepted,
    setPolicyholderIsValid,
    resetPolicyholderData,
    setFoundPolicyholder,
    policyholderType,
    setPolicyholderActiveElement,
  } = props;
  const { embedParams } = useEmbedParamsContext();
  const { auth, environment, organizationId } = embedParams;
  const { siteConfig } = useSiteConfigContext();
  const [activeElement, setActiveElement] = useState<ActiveElement>({
    elementId: '',
  });

  const defaultCountryCodeFromBrowser = siteConfig?.settings?.defaultCountryCodeFromBrowser;
  const disableSteppedComponents = !!siteConfig?.styles.disableSteppedComponents;

  const form = useForm<PersonalDetailsInputsFormData>({
    mode: 'onChange',
    defaultValues: useMemo(
      () => ({
        [PersonalDetailsInputs.IdType]: policyholderLookupFormData.idType,
        [PersonalDetailsInputs.IdentificationNumber]: policyholderLookupFormData.identificationNumber,
        [PersonalDetailsInputs.IdentificationCountry]: policyholderLookupFormData.identificationCountry,
        [PersonalDetailsInputs.Email]: policyholderLookupFormData.email,
        [PersonalDetailsInputs.Cellphone]: policyholderLookupFormData.cellphone,
        [PersonalDetailsInputs.RegistrationNumber]: policyholderLookupFormData.registrationNumber,
      }),
      [policyholderLookupFormData],
    ),
  });

  const [steppedComponentsBehavior] = useState<SteppedComponentsBehavior>({
    disableNextButton: disableSteppedComponents,
    hideDivider: disableSteppedComponents,
    disableScrollToElement: disableSteppedComponents,
    disableActiveElement: disableSteppedComponents,
    isTouched: !disableSteppedComponents ? form.formState.isValid : true,
  });

  useEffect(() => {
    setPolicyholderIsValid(form.formState.isValid);
  }, [form.formState.isValid]);

  const { error: errorAutoSubmit, isLoading: isLoadingAutoSubmit } = usePromise(async () => {
    const skipOnPrefill = siteConfig?.personalDetails.displayOptionalSections.skipOnPrefill;

    if (skipOnPrefill && policyholderPrefillValues) {
      const isComplete = isPolicyholderLookupPrefillComplete({
        identificationSectionData: setPolicyholderState({ policyholderPrefillValues }),
      });

      if (isComplete) {
        try {
          const data = {
            registrationNumber: policyholderPrefillValues.registrationNumber,
            identificationNumber: policyholderPrefillValues.identification?.number,
            email: policyholderPrefillValues.email,
            cellphone: policyholderPrefillValues.cellphone && {
              countryCode: policyholderPrefillValues.cellphone.country,
              number: policyholderPrefillValues.cellphone.number,
            },
            passportNumber: policyholderPrefillValues.identification?.number,
            identificationCountry: policyholderPrefillValues.identification?.country,
            idType: policyholderPrefillValues.identification?.type,
            policyholderType: policyholderPrefillValues.policyholderType,
          };
          setPolicyholderLookupFormData(data);

          const policyholder = await lookupUnauthenticatedPolicyholder({
            auth,
            data,
            policyholderEntityType: policyholderPrefillValues.policyholderType,
            environment,
            organizationId,
          });

          setFoundPolicyholder(policyholder);
          return setLookupPolicyholderModalOpen(true);
        } catch (error: any) {
          const errorMessage = error?.message;

          if (
            errorMessage !== 'Policyholder with active policy not found' &&
            errorMessage !== 'Policyholder not found'
          ) {
            throw error;
          }
        }

        setNewPersonalDetailsAccepted(true);
      }
    }
  }, []);

  useEffect(() => {
    return () => {
      props.setIsLoadingPolicyholderLookup(false)
    }
  })

  const {
    execute: submitForm,
    isLoading: isLoadingSubmitForm,
    error: submitFormError,
  } = usePromiseLazy(async () => {
    if (await form.trigger()) {
      await form.handleSubmit(async (data: PolicyholderLookUpFormData) => {
        try {
          const policyholder = await lookupUnauthenticatedPolicyholder({
            auth,
            data,
            environment,
            organizationId,
            policyholderEntityType: policyholderType,
          });
          setPolicyholderLookupFormData(data);
          setFoundPolicyholder(policyholder);
          return setLookupPolicyholderModalOpen(true);
        } catch (error: any) {
          const errorMessage = error?.message;
          if (
            errorMessage !== 'Policyholder with active policy not found' &&
            errorMessage !== 'Policyholder not found'
          ) {
            throw error;
          }
        }
        // TODO - we need a better way of validating if the policyholder in memory is the Obfuscated policyholder
        if (policyholder?.firstName === '****') {
          resetPolicyholderData();
        }
        if (policyholderType === PolicyholderEntityType.Company) {
          setPolicyholderActiveElement({ elementId: PersonalDetailsInputs.CompanyName });
        }
        setPolicyholderLookupFormData(data);
        setNewPersonalDetailsAccepted(true);
      })();
    }
  }, []);

  useEffect(() => {
    props.setIsLoadingPolicyholderLookup(isLoadingSubmitForm);
  }, [isLoadingSubmitForm]);

  if (!productModule || isLoading) {
    return (
      <FormDetailsWrapper siteConfig={siteConfig}>
        <LoadingLines />
      </FormDetailsWrapper>
    );
  }

  const idType = getIdType({
    formIdType: form.watch(PersonalDetailsInputs.IdType) as IdentificationType,
    productModule,
  });
  const idTypes = getIdTypes(productModule);

  return (
    <>
      {isLookupPolicyholderModalOpen && (
        <PolicyholderLookUpModal
          resetPolicyholderData={resetPolicyholderData}
          setLookupPolicyholderModalOpen={setLookupPolicyholderModalOpen}
          setNewPersonalDetailsAccepted={setNewPersonalDetailsAccepted}
          foundPolicyholder={foundPolicyholder}
          setCurrentStepKey={setCurrentStepKey}
          setPolicyholder={setPolicyholder}
        />
      )}
      <FormWrapperStyle>
        {isLoadingAutoSubmit && (
          <FormDetailsWrapper siteConfig={siteConfig}>
            <LoadingLines />
          </FormDetailsWrapper>
        )}
        <ErrorAlert error={lookupPolicyholderError || errorAutoSubmit || submitFormError} displayFieldError />
        <CardGroup>
          <form
            style={isLoadingAutoSubmit ? { display: 'none' } : {}}
            onSubmit={form.handleSubmit(setPolicyholderLookupFormData)}
          >
            {policyholderType !== PolicyholderEntityType.Company && (
              <>
                <Row>
                  <Col sm={12}>
                    <IdTypeSelectField
                      clearable={true}
                      placeholder={undefined}
                      idTypes={idTypes}
                      defaultValue={getIdTypeValue({
                        identificationType:
                          policyholderLookupFormData.idType || policyholderPrefillValues.identification?.type,
                        idTypes,
                      })}
                      validators={[
                        {
                          validation: {
                            type: ValidationTypes.REQUIRED,
                          },
                        },
                      ]}
                      name={PersonalDetailsInputs.IdType}
                      label={getWording({ wording: siteConfig?.inputFields.personalDetails.idType.label })}
                      prefillValue={policyholderPrefillValues.identification?.type}
                      prefillAction={siteConfig?.inputFields.personalDetails.idType.prefillAction}
                      form={form}
                      isDisabled={idTypes.length <= 1}
                      displayProperties={
                        {
                          activeElement,
                          setActiveElement,
                          nextComponentName: getNextComponentName(policyholderLookupFormData),
                          index: 0,
                        } as InputFieldDisplayProperties
                      }
                      {...steppedComponentsBehavior}
                    />
                  </Col>
                </Row>
                <Row>
                  {idType === IdentificationType.Id && (
                    <Col sm={12}>
                      <TextField
                        placeholder={undefined}
                        defaultValue={
                          policyholderLookupFormData?.identificationNumber ||
                          policyholderPrefillValues.identification?.number
                        }
                        validators={[
                          {
                            validation: {
                              type: ValidationTypes.REQUIRED,
                            },
                          },
                          {
                            validation: {
                              type: ValidationTypes.ZAId,
                            },
                          },
                        ]}
                        name={PersonalDetailsInputs.IdentificationNumber}
                        label={getWordingForIdTypeHandlebars({
                          wording: siteConfig?.inputFields.personalDetails.identificationNumber.label,
                          identificationType: idType,
                        })}
                        form={form}
                        prefillValue={policyholderPrefillValues.identification?.number}
                        prefillAction={siteConfig?.inputFields.personalDetails.identificationNumber.prefillAction}
                        displayProperties={
                          {
                            activeElement,
                            setActiveElement,
                          } as InputFieldDisplayProperties
                        }
                        {...steppedComponentsBehavior}
                      />
                    </Col>
                  )}
                  {idType === IdentificationType.Passport && (
                    <>
                      <Col sm={12}>
                        <TextField
                          placeholder={undefined}
                          defaultValue={
                            policyholderLookupFormData?.identificationNumber ||
                            policyholderPrefillValues.identification?.number
                          }
                          validators={[
                            {
                              validation: {
                                type: ValidationTypes.REQUIRED,
                              },
                            },
                          ]}
                          name={PersonalDetailsInputs.IdentificationNumber}
                          label={getWordingForIdTypeHandlebars({
                            wording: siteConfig?.inputFields.personalDetails.identificationNumber.label,
                            identificationType: idType,
                          })}
                          prefillValue={policyholderPrefillValues.identification?.number}
                          prefillAction={siteConfig?.inputFields.personalDetails.identificationNumber.prefillAction}
                          form={form}
                          displayProperties={
                            {
                              activeElement,
                              setActiveElement,
                              nextComponentName: PersonalDetailsInputs.IdentificationCountry,
                            } as InputFieldDisplayProperties
                          }
                          {...steppedComponentsBehavior}
                        />
                      </Col>
                      <Col sm={12}>
                        <CountrySelectField
                          clearable={true}
                          placeholder={undefined}
                          validators={[
                            {
                              validation: {
                                type: ValidationTypes.REQUIRED,
                              },
                            },
                          ]}
                          form={form}
                          label={getWordingForIdTypeHandlebars({
                            wording: siteConfig?.inputFields.personalDetails.identificationCountry.label,
                            identificationType: idType,
                          })}
                          name={PersonalDetailsInputs.IdentificationCountry}
                          prefillValue={policyholderPrefillValues.identification?.country}
                          prefillAction={siteConfig?.inputFields.personalDetails.identificationCountry.prefillAction}
                          defaultValue={
                            policyholderLookupFormData.identificationCountry ||
                            policyholderPrefillValues.identification?.country ||
                            (defaultCountryCodeFromBrowser && BCP47ToIsoCountryCode()) ||
                            'ZA'
                          }
                          displayProperties={
                            {
                              activeElement,
                              setActiveElement,
                            } as InputFieldDisplayProperties
                          }
                          {...steppedComponentsBehavior}
                        />
                      </Col>
                    </>
                  )}
                  {idType === IdentificationType.Email && (
                    <Col sm={12}>
                      <InputField
                        placeholder={undefined}
                        defaultValue={policyholderLookupFormData?.email || policyholderPrefillValues.email}
                        validators={[
                          {
                            validation: {
                              type: ValidationTypes.REQUIRED,
                            },
                          },
                          {
                            validation: {
                              type: ValidationTypes.EMAIL,
                            },
                          },
                        ]}
                        name={PersonalDetailsInputs.Email}
                        label={getWording({ wording: siteConfig?.inputFields.personalDetails.email.label })}
                        prefillValue={policyholderPrefillValues.identification?.number}
                        prefillAction={siteConfig?.inputFields.personalDetails.email.prefillAction}
                        form={form}
                        displayProperties={
                          {
                            activeElement,
                            setActiveElement,
                            // nextComponentName: displayCellphoneNumber
                            //   ? PersonalDetailsInputs.Cellphone
                            //   : PersonalDetailsInputs.AddressOptIn,
                          } as InputFieldDisplayProperties
                        }
                        {...steppedComponentsBehavior}
                      />
                    </Col>
                  )}
                  {idType === IdentificationType.Cellphone && (
                    <Col sm={12}>
                      <PhoneNumberField
                        placeholder={undefined}
                        defaultValue={defaultValues({
                          cellphone: policyholderLookupFormData.cellphone || policyholderPrefillValues.cellphone,
                          defaultCountryCodeFromBrowser,
                        })}
                        validators={[
                          {
                            validation: {
                              type: ValidationTypes.REQUIRED,
                            },
                          },
                        ]}
                        name={PersonalDetailsInputs.Cellphone}
                        label={getWording({ wording: siteConfig?.inputFields.personalDetails.cellphone.label })}
                        prefillValue={policyholderPrefillValues.identification?.number}
                        prefillAction={siteConfig?.inputFields.personalDetails.cellphone.prefillAction}
                        form={form}
                        displayProperties={
                          {
                            activeElement,
                            setActiveElement,
                          } as InputFieldDisplayProperties
                        }
                        {...steppedComponentsBehavior}
                      />
                    </Col>
                  )}
                </Row>
              </>
            )}
            {policyholderType === PolicyholderEntityType.Company && (
              <Row>
                <Col sm={12}>
                  <InputField
                    defaultValue={
                      policyholderLookupFormData?.registrationNumber || policyholderPrefillValues?.registrationNumber
                    }
                    isTouched={true}
                    placeholder={undefined}
                    disableScrollToElement={true}
                    name={PersonalDetailsInputs.RegistrationNumber}
                    label={getWording({ wording: siteConfig?.inputFields.personalDetails.registrationNumber.label })}
                    prefillAction={siteConfig?.inputFields.personalDetails.registrationNumber.prefillAction}
                    hideDivider={false}
                    form={form}
                    validators={[
                      {
                        validation: {
                          type: ValidationTypes.REQUIRED,
                        },
                      },
                    ]}
                    disableNextButton
                    prefillValue={policyholderPrefillValues.registrationNumber}
                    displayProperties={
                      {
                        activeElement,
                        setActiveElement,
                      } as InputFieldDisplayProperties
                    }
                  />
                </Col>
              </Row>
            )}
            <button
              style={{ display: 'none' }}
              onClick={submitForm}
              ref={createPolicyholderSubmitButtonRef}
              type='submit'
            />
          </form>
        </CardGroup>
      </FormWrapperStyle>
    </>
  );
};

const defaultValues = (params: {
  cellphone: ContactDetailCellphone | Cellphone | undefined;
  defaultCountryCodeFromBrowser: boolean | undefined;
}) => {
  const { defaultCountryCodeFromBrowser, cellphone } = params;

  return {
    countryCode:
      (cellphone as ContactDetailCellphone)?.countryCode ||
      (cellphone as Cellphone)?.country ||
      phone(cellphone?.number || '').countryIso2 ||
      (defaultCountryCodeFromBrowser && BCP47ToIsoCountryCode()) ||
      'ZA',
    number: cellphone?.number?.replace(phone(cellphone?.number || '').countryCode || '', ''),
  };
};

const getNextComponentName = (policyholderLookUpFormData: PolicyholderLookUpFormData): PersonalDetailsInputs => {
  const { idType } = policyholderLookUpFormData;

  if (!idType) {
    return PersonalDetailsInputs.IdentificationNumber;
  }

  const nextComponentMapping: {
    [key in IdentificationType]: PersonalDetailsInputs;
  } = {
    [IdentificationType.Id]: PersonalDetailsInputs.IdentificationNumber,
    [IdentificationType.Passport]: PersonalDetailsInputs.IdentificationNumber,
    [IdentificationType.Custom]: PersonalDetailsInputs.IdentificationNumber,
    [IdentificationType.Email]: PersonalDetailsInputs.Email,
    [IdentificationType.Cellphone]: PersonalDetailsInputs.Cellphone,
    [IdentificationType.Company]: PersonalDetailsInputs.CompanyName,
  };

  return nextComponentMapping[idType];
};

//w
