import { ProductModule } from 'product-modules/domain/product-module';
import React from 'react';
import { ActiveElement } from 'rootstrap/components/forms/new-fields/utils';
import { CompanyDetailsSection, PersonalDetailsCompanyDetailsData } from './company-details';
import { ContactDetailsData, ContactDetailsSection } from './contact-details';
import { IdentificationSection, PersonalDetailsIdentificationData } from './identification';
import { PolicyholderPrefillValues } from './personal-details';
import { PersonalAddressData, PhysicalAddressSection } from './physical-address-section';
import { Address } from 'policyholder/domain/policyholder';
import { PolicyholderLookUpFormData } from './unauthenticated-policyholder-lookup';

interface Props {
  activeElement: ActiveElement;
  isCompleted: boolean;
  isPolicyholderTypeFieldDisplayed: boolean;
  personalDetailCompanyFormSectionData: PersonalDetailsCompanyDetailsData | undefined;
  policyholderPrefillValues: PolicyholderPrefillValues;
  setActiveElement: (params: ActiveElement) => void;
  setCompanyDetailsIsValid: (params: boolean) => void;
  setCompanyDetailsSectionData: (params: PersonalDetailsCompanyDetailsData) => void;
  setAddressDetailsIsValid: (addressDetailsIsValid: boolean) => void;
  addressDetailsSectionData: PersonalAddressData | undefined;
  setAddressDetailsSectionData: (addressDetailsSectionData: PersonalAddressData) => void;
  setContactDetailsIsValid: (params: boolean) => void;
  contactDetailsSectionData: ContactDetailsData | undefined;
  setContactDetailsSectionData: (contactDetailsSectionData: ContactDetailsData) => void;
  identificationSectionData: PersonalDetailsIdentificationData | undefined;
  setIdentificationIsValid: (v: boolean) => void;
  setIdentificationSectionData: (identificationSectionData: PersonalDetailsIdentificationData) => void;
  productModule?: ProductModule;
  addressOptIn: boolean | undefined;
  setAddressOptIn: (v: boolean) => void;
  fetchifyData: Address | undefined;
  setFetchifyData: (v: Address | undefined) => void;
  policyholderLookupFormData: PolicyholderLookUpFormData;
}

export const CompanyPolicyholderForm = (props: Props) => {
  const {
    activeElement,
    isCompleted,
    isPolicyholderTypeFieldDisplayed,
    policyholderPrefillValues,
    personalDetailCompanyFormSectionData,
    setActiveElement,
    setCompanyDetailsIsValid,
    setCompanyDetailsSectionData,
    setAddressDetailsIsValid,
    addressDetailsSectionData,
    setAddressDetailsSectionData,
    contactDetailsSectionData,
    setContactDetailsIsValid,
    setContactDetailsSectionData,
    identificationSectionData,
    setIdentificationIsValid,
    setIdentificationSectionData,
    productModule,
    addressOptIn,
    setAddressOptIn,
    fetchifyData,
    setFetchifyData,
    policyholderLookupFormData,
  } = props;

  return (
    <>
      <CompanyDetailsSection
        policyholderLookupFormData={policyholderLookupFormData}
        activeElement={activeElement}
        isCompleted={isCompleted}
        isPolicyholderTypeFieldDisplayed={isPolicyholderTypeFieldDisplayed}
        personalDetailCompanyFormSectionData={personalDetailCompanyFormSectionData}
        prefillValues={policyholderPrefillValues}
        setActiveElement={setActiveElement}
        setIsValid={(companyDetailsIsValid) => setCompanyDetailsIsValid(companyDetailsIsValid)}
        setSectionData={(companyDetailsSectionData) => setCompanyDetailsSectionData(companyDetailsSectionData)}
      />
      <IdentificationSection
        policyholderLookupFormData={undefined} // Not yet wired up
        isCompany
        isPolicyholderTypeFieldDisplayed={isPolicyholderTypeFieldDisplayed}
        identificationSectionData={identificationSectionData}
        setIsValid={(identificationIsValid) => setIdentificationIsValid(identificationIsValid)}
        setSectionData={(identificationSectionData) => setIdentificationSectionData(identificationSectionData)}
        productModule={productModule}
        prefillValues={policyholderPrefillValues}
        setActiveElement={setActiveElement}
        activeElement={activeElement}
        isCompleted={isCompleted}
      />
      <ContactDetailsSection
        setIsValid={(contactDetailsIsValid) => setContactDetailsIsValid(contactDetailsIsValid)}
        setSectionData={(contactDetailsSectionData) => setContactDetailsSectionData(contactDetailsSectionData)}
        contactDetailsSectionData={contactDetailsSectionData}
        prefillValues={policyholderPrefillValues}
        setActiveElement={setActiveElement}
        activeElement={activeElement}
        isCompleted={isCompleted}
        policyholderLookupFormData={undefined}
      />
      <PhysicalAddressSection
        isCompany
        fetchifyData={fetchifyData}
        setFetchifyData={setFetchifyData}
        addressOptIn={addressOptIn}
        setAddressOptIn={setAddressOptIn}
        setIsValid={setAddressDetailsIsValid}
        addressDetailsSectionData={addressDetailsSectionData}
        productModule={productModule}
        setSectionData={(addressDetailsSectionData) => setAddressDetailsSectionData(addressDetailsSectionData)}
        prefillValues={policyholderPrefillValues}
        setActiveElement={setActiveElement}
        activeElement={activeElement}
        isCompleted={isCompleted}
      />
    </>
  );
};
