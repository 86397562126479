import { Environment } from 'models/environment';
import { PolicyholderLookUpFormData } from 'policy-issuing/personal-details/views/unauthenticated-policyholder-lookup';
import { NetworkObfuscatedPolicyholder, Policyholder, PolicyholderEntityType } from 'policyholder/domain/policyholder';
import { IdentificationType } from 'policyholder/domain/policyholder-identification';
import { EmbedAuth, apiRequest, getApiHost, getBasePathname } from 'shared/api';
import { buildApiUrl } from 'shared/utils';

interface Params {
  organizationId: string;
  auth: EmbedAuth;
  data: PolicyholderLookUpFormData;
  environment: Environment;
  policyholderEntityType: PolicyholderEntityType | undefined;
}

export const lookupUnauthenticatedPolicyholder = async ({
  organizationId,
  auth,
  data,
  environment,
  policyholderEntityType,
}: Params) => {
  const queryParams =
    policyholderEntityType === PolicyholderEntityType.Company
      ? { registration_number: data.registrationNumber }
      : {
          identification_type: data.idType,
          identification_number: getImplicitIdNumberFromType(data),
          identification_country:
            data.idType === IdentificationType.Cellphone
              ? data.cellphone?.countryCode
              : data.idType === IdentificationType.Passport
              ? data.identificationCountry
              : undefined,
        };

  const apiUrl = buildApiUrl(
    `${getApiHost({ environment })}${getBasePathname({
      organizationId,
      auth,
      clientAppAuth: true,
    })}/policyholders/lookup`,
    queryParams,
  );

  const response = await apiRequest({
    url: apiUrl,
    method: 'get',
    auth,
  });

  const networkObfuscatedPolicyholder: NetworkObfuscatedPolicyholder = await response.json();
  const policyholder = Policyholder.fromObfuscated(networkObfuscatedPolicyholder);

  return policyholder;
};

const getImplicitIdNumberFromType = (policyholderLookUpFormData: Partial<PolicyholderLookUpFormData>) => {
  if (policyholderLookUpFormData.idType === IdentificationType.Email) {
    return policyholderLookUpFormData.email || policyholderLookUpFormData.identificationNumber;
  }

  if (policyholderLookUpFormData.idType === IdentificationType.Cellphone) {
    return policyholderLookUpFormData.cellphone?.number || policyholderLookUpFormData.identificationNumber;
  }

  return policyholderLookUpFormData.identificationNumber;
};
