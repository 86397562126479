import React, { ReactNode } from 'react';
import { Form as ReactstrapForm } from 'reactstrap';
import { debounce } from 'utils';
import { formatError } from '../../../shared/format-error';
import { Alert } from '../alerts/alert';

export const FormContainer = ({ children }: { children: ReactNode }) => {
  return <div style={{ width: '440px', margin: '0 auto' }}>{children}</div>;
};

export const Form = (params: {
  onSubmit: (data: any) => void | Promise<void>;
  onChange?: (data: any) => void | Promise<void>;
  setIsLoading: (isLoading: boolean) => void;
  // Forcing devs to implement an error, or have to explicitly pass 'null'.
  error: { title: string; body?: ReactNode; error?: any } | null;
  children: ReactNode;
}) => {
  const { children, error, onSubmit, onChange, setIsLoading } = params;
  return (
    <ReactstrapForm
      onBlur={(data) => {
        setIsLoading(true);
        debounce('on-form-blur', () => onChange && onChange(data), 10);
      }}
      onChange={(data) => {
        setIsLoading(true);
        onChange && onChange(data);
      }}
      onSubmit={onSubmit}
      autoComplete='off'
    >
      {error && (
        <Alert
          variant='danger'
          title={error.title || 'Something went wrong'}
          body={
            error.error
              ? formatError(error.error)
              : error.body || 'Please try again or contact support@root.co.za if the problem persists.'
          }
        />
      )}
      {children}
    </ReactstrapForm>
  );
};
