import { IdentificationType } from 'policyholder/domain/policyholder-identification';
import { ProductModule } from 'product-modules/domain/product-module';
import { PolicyholderLookUpFormData } from '../views/unauthenticated-policyholder-lookup';
import { getWording, ProductModuleDefinitionEmbeddedConfig } from 'site-config';
import { IssuingSceneStepKeys, StaticSceneStepKeys } from 'policy-issuing/utils';
import { PolicyholderEntityType } from 'policyholder/domain/policyholder';

export const getSelectableIdTypes = (idTypes: IdentificationType[] | undefined): IdentificationType[] => {
  const idTypeToSelectableIdTypeMap: { [key in IdentificationType]: IdentificationType | undefined } = {
    [IdentificationType.Id]: IdentificationType.Id,
    [IdentificationType.Passport]: IdentificationType.Passport,
    [IdentificationType.Custom]: undefined,
    [IdentificationType.Email]: IdentificationType.Email,
    [IdentificationType.Cellphone]: IdentificationType.Cellphone,
    [IdentificationType.Company]: IdentificationType.Company,
  };

  if (!idTypes) return [];

  const filteredTypes = idTypes
    .filter((idType) => idTypeToSelectableIdTypeMap[idType] !== undefined)
    .map((idType) => idTypeToSelectableIdTypeMap[idType] as IdentificationType);

  return filteredTypes.length ? filteredTypes : [];
};

export const getIdTypeValue = (params: {
  identificationType: IdentificationType | IdentificationType | undefined;
  idTypes: IdentificationType[];
  // prefillValue: IdentificationType | undefined;
}): IdentificationType | undefined => {
  const { idTypes, identificationType } = params;

  if (idTypes.includes(identificationType as IdentificationType)) {
    return identificationType as IdentificationType;
  }

  if (idTypes.length === 1) {
    return idTypes[0];
  }
};

export const getIdType = (params: { formIdType: IdentificationType | undefined; productModule: ProductModule }) => {
  const { formIdType, productModule } = params;

  if (productModule.productModuleDefinition?.settings.policyholder.idTypes.length === 1) {
    return productModule.productModuleDefinition?.settings.policyholder.idTypes[0];
  }

  return formIdType;
};

export const getIdTypes = (productModule: ProductModule): IdentificationType[] => {
  const selectableIdTypes = getSelectableIdTypes(productModule.productModuleDefinition?.settings.policyholder.idTypes);
  return selectableIdTypes;
};

export const isPrefilledFromLookupData = (
  policyholderLookupData: PolicyholderLookUpFormData | undefined,
  identificationType: IdentificationType | undefined,
) => {
  if (!policyholderLookupData) {
    return false;
  }

  const { idType } = policyholderLookupData;

  switch (idType) {
    case IdentificationType.Id:
      return identificationType === IdentificationType.Id;
    case IdentificationType.Passport:
      return identificationType === IdentificationType.Passport;
    case IdentificationType.Email:
      return identificationType === IdentificationType.Email;
    case IdentificationType.Cellphone:
      return identificationType === IdentificationType.Cellphone;
  }
};

export const getStepHeaderDetails = (
  stepKey: IssuingSceneStepKeys | undefined,
  siteConfig: ProductModuleDefinitionEmbeddedConfig | null,
) => {
  if (!stepKey) {
    return { title: '', description: '' };
  }

  const mapping: { [key in StaticSceneStepKeys]: () => { title: string; description: string } } = {
    [StaticSceneStepKeys.PersonalDetails]: () => ({
      title: siteConfig?.personalDetails.wording.title || '',
      description: siteConfig?.personalDetails.wording.description || '',
    }),
    [StaticSceneStepKeys.PrePersonalDetailsConsent]: () => ({
      title: siteConfig?.prePersonalDetailsCompliance?.wording.title || '',
      description: siteConfig?.prePersonalDetailsCompliance?.wording.description || '',
    }),
    [StaticSceneStepKeys.PrePaymentConsent]: () => ({
      title: siteConfig?.prePaymentCompliance?.wording.title || '',
      description: siteConfig?.prePaymentCompliance?.wording.description || '',
    }),
    [StaticSceneStepKeys.Payment]: () => ({
      title: siteConfig?.payment.wording.title || '',
      description: siteConfig?.payment.wording.description || '',
    }),
    [StaticSceneStepKeys.Application]: () => ({
      title: siteConfig?.application.wording.title || '',
      description: siteConfig?.application.wording.description || '',
    }),
    [StaticSceneStepKeys.ScreeningQuestions]: () => ({
      title: siteConfig?.quote?.wording.screeningQuestionsDescription || '',
      description: '',
    }),
    [StaticSceneStepKeys.Beneficiaries]: () => ({
      title: siteConfig?.beneficiaries?.wording.title || '',
      description: siteConfig?.beneficiaries?.wording.description || '',
    }),
  };

  // Handle static step keys

  if (Object.values(StaticSceneStepKeys).includes(stepKey as StaticSceneStepKeys)) {
    const staticStepKey = stepKey as StaticSceneStepKeys;
    return mapping[staticStepKey]();
  }

  // Handle dynamic quoteInputStep steps
  if (stepKey.startsWith('quoteInputStep')) {
    return {
      title: siteConfig?.quote.wording.title,
      description: getWording({ wording: siteConfig?.quote.wording.description }),
    };
  }

  // Handle unknown step keys
  throw new Error(`Unknown step key: ${stepKey}`);
};
