import React from 'react';
import { orderBy } from 'lodash';
import { SelectFieldParams, SelectField } from './select-field';
import { IdentificationType } from 'policyholder/domain/policyholder-identification';

export const IdTypeSelectField = (
  props: Omit<SelectFieldParams<IdentificationType>, 'options'> & { idTypes?: IdentificationType[] },
) => {
  const options: { value: IdentificationType; label: string }[] = props.idTypes
    ? props.idTypes.map((i) => ({
        label: idLabelMappings[i],
        value: i,
      }))
    : [
        {
          label: idLabelMappings[IdentificationType.Id],
          value: IdentificationType.Id,
        },
        {
          label: idLabelMappings[IdentificationType.Passport],
          value: IdentificationType.Passport,
        },
      ];

  return <SelectField {...props} searchable options={orderBy(options, (value) => value.value)} clearable={true} />;
};

const idLabelMappings: {
  [key in IdentificationType]: string;
} = {
  [IdentificationType.Id]: 'ID number',
  [IdentificationType.Passport]: 'Passport',
  [IdentificationType.Email]: 'Email',
  [IdentificationType.Cellphone]: 'Cellphone',
  [IdentificationType.Company]: 'Company',
  [IdentificationType.Custom]: 'Identifier',
};
