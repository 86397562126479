import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ValidationTypes } from 'rootstrap/components-old/root-schema-form/utils/validation';
import { InputFieldDisplayProperties } from 'rootstrap/components/forms/new-fields/input-field';
import { RadioField } from 'rootstrap/components/forms/new-fields/radio-field';
import { ActiveElement, setFormFocusOrBlur } from 'rootstrap/components/forms/new-fields/utils';
import { debounce } from 'utils';
import { PersonalDetailsInputs, PolicyholderPrefillValues } from './personal-details';
import { getWording } from 'site-config';
import { useSiteConfigContext } from 'style-context';
import { isMobile } from 'react-device-detect';
import { PolicyholderEntityType } from 'policyholder/domain/policyholder';
import { PolicyholderLookUpFormData } from './unauthenticated-policyholder-lookup';

interface PersonalDetailsInputsFormData {
  [PersonalDetailsInputs.PolicyholderType]: string;
}

interface Props {
  policyholderType: PolicyholderEntityType | undefined;
  setPolicyholderType: (params: PolicyholderEntityType | undefined) => void;
  activeElement: ActiveElement;
  setActiveElement: (params: ActiveElement) => void;
  isCompleted: boolean;
  policyholderPrefillValues: PolicyholderPrefillValues;
  policyholderLookupFormData: PolicyholderLookUpFormData | undefined;
}

interface FormData {
  policyholderType?: PolicyholderEntityType;
}

export const PersonalDetailsTypeSection = (props: Props) => {
  const {
    policyholderType,
    setPolicyholderType,
    activeElement,
    setActiveElement,
    isCompleted,
    policyholderPrefillValues,
    policyholderLookupFormData,
  } = props;
  const [isTouched] = useState<boolean>(isCompleted);
  const { siteConfig } = useSiteConfigContext();

  const form = useForm<PersonalDetailsInputsFormData>({
    mode: 'onChange',
    defaultValues: useMemo(
      () => ({ policyholderType: policyholderType || policyholderPrefillValues.policyholderType }),
      [],
    ),
  });
  form.watch();

  const onSubmit = (policyholderType: PolicyholderEntityType | undefined) => {
    setPolicyholderType(policyholderType);
  };

  useEffect(() => {
    if (policyholderPrefillValues.policyholderType) {
      onSubmit(policyholderType || policyholderPrefillValues.policyholderType);
    }
  }, [policyholderPrefillValues.policyholderType]);

  const policyholderTypeFormComponent = form.watch('policyholderType');

  useEffect(() => {
    const scrollToId =
      policyholderTypeFormComponent === PolicyholderEntityType.Individual
        ? PersonalDetailsInputs.FirstName
        : PersonalDetailsInputs.RegistrationNumber;

    if (isMobile) {
      debounce(
        'select-personal-details-type',
        () => {
          setFormFocusOrBlur({
            activeElement: {
              elementId: scrollToId,
            },
            scrollToId: scrollToId,
            disableScrollToElement: false,
            isFirstElement: false,
          });
        },
        200,
      );
    }
  }, [policyholderTypeFormComponent]);

  return (
    <form
      onBlur={form.handleSubmit((data: FormData) => onSubmit(data.policyholderType))}
      onChange={form.handleSubmit((data: FormData) => onSubmit(data.policyholderType))}
    >
      <RadioField
        isTouched={isTouched}
        form={form}
        name={PersonalDetailsInputs.PolicyholderType}
        label={getWording({ wording: siteConfig?.inputFields.personalDetails.policyholderType.label })}
        isDisabled={!!policyholderLookupFormData?.idType || !!policyholderLookupFormData?.registrationNumber}
        prefillValue={policyholderPrefillValues.policyholderType}
        placeholder={undefined}
        disableScrollToElement={true}
        defaultValue={policyholderType || policyholderPrefillValues.policyholderType}
        hideDivider={false}
        options={[
          { label: 'Individual', value: 'individual' },
          { label: 'Company', value: 'company' },
        ]}
        displayProperties={
          {
            activeElement,
            setActiveElement,
            nextComponentName: '',
          } as InputFieldDisplayProperties
        }
        validators={[
          {
            validation: {
              type: ValidationTypes.REQUIRED,
            },
          },
        ]}
      />
    </form>
  );
};
