import { Currency } from 'product-modules/domain/product-module-definition-settings';

export interface CurrencyOptions {
  currencyCode?: Currency | string | undefined;
  excludeCents: boolean;
  excludeSymbol?: boolean;
}

export const formatToCurrency = (params: { number: number | string; currencyOptions?: CurrencyOptions }) => {
  const { number, currencyOptions } = params;
  const formattedNumber = typeof number === 'string' ? parseInt(number.replace(/[^0-9-.]/g, '')) : number;
  const { currencyCode, excludeSymbol, excludeCents } = currencyOptions || {};

  const validCurrencyCode = Object.keys(Currency).includes(currencyOptions?.currencyCode || '');
  const shouldExcludeSymbol = validCurrencyCode ? !!excludeSymbol : true;

  const locale = navigator.language;
  const symbolType = CurrenciesToDisplayFullSymbols.includes(currencyCode as Currency) ? 'symbol' : 'narrowSymbol';

  const formatterOptions: Intl.NumberFormatOptions = {
    style: 'currency',
    currency: (validCurrencyCode && currencyCode) || 'ZAR',
    currencyDisplay: shouldExcludeSymbol ? 'code' : symbolType,
    maximumFractionDigits: excludeCents ? 0 : 2,
    minimumFractionDigits: excludeCents ? 0 : 2,
  };
  const formatter = new Intl.NumberFormat(locale, {
    ...formatterOptions,
    useGrouping: true,
  });

  let formattedCurrency = shouldExcludeSymbol
    ? formatter
        .format(formattedNumber / (excludeCents ? 1 : 100))
        .replace(/[A-Za-z]/g, '')
        .trim()
    : formatter.format(formattedNumber / (excludeCents ? 1 : 100));

  if (!validCurrencyCode && typeof currencyCode === 'string') {
    formattedCurrency = `${currencyCode} ${formattedCurrency}`;
  }

  return formattedCurrency;
};

export const getCurrencySymbol = (params: { currencyCode: Currency | string | undefined }) => {
  const { currencyCode } = params;
  return formatToCurrency({ number: 0, currencyOptions: { currencyCode, excludeCents: true } })
    .replace(/[\d\s.,]+/g, '')
    .trim();
};

const CurrenciesToDisplayFullSymbols = [Currency.AUD];
